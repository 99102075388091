.nb {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    background-color: #f9f9f9;
    z-index: 99;
}

.nb-menu-wrapper {
    width: 1200px;
    padding-left: 50px;
    padding-right: 50px;
}

.nb-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 0.88rem;
}

.nb-right {
    display: flex;
    justify-content: space-evenly;
}

.nb-right div {
    margin-left: 35px;
}

.nb-right-menu {
    display: none;
    cursor: copy;
    z-index: 99;
}

.nb-span {
    position: relative;
    display: block;
    font-weight: 500;
    letter-spacing: 1.1px;
}
  
.nb-span:before, .nb-span:after {
    content: '';
    position: absolute;
    width: 0%;
    height: 1px;
    top: 50%;
    margin-top: -0.5px;
    background: black;
}

.nb-span:before {
    left: -2.5px;
}

.nb-span:after {
    right: 2.5px;
    background: black;
    transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.nb-span:hover:before {
    background: black;
    width: 100%;
    transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.nb-span:hover:after {
    background: transparent;
    width: 100%;
    transition: 0s;
}

.hamburger-react {
    cursor: none !important;
}

.nb-a {
    color: black;
    text-decoration: none;
}

@media only screen and (max-width: 800px) {
    .nb-right-menu {
        display: block;
    }

    .nb-right {
        display: none;
    }
    
    .nb-left-home {
        display: none;
    }

    .nb-wrapper {
        font-size: 1rem;
    }
}