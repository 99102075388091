.ab {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
}

.ab-title {
    display: flex;
}

.ab-title-wrapper {
    margin-top: 35px;
    margin-bottom: 35px;
}

.ab-title-wrapper span {
    margin-top: 70px;
    margin-bottom: 70px;
}

.ab-span {
    font-size: 6.5em;
    font-weight: 500;
    margin-left: 10px;
    text-align: center;
}

.ab-pic {
    box-shadow: rgba(50, 50, 93, 0.7) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    margin-top: 8px;
}

.ab-container-all {
    max-width: 1250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ab-details img {
    /* width: 340px;
    height: 420px; */
    object-fit: cover;
}

.ab-details {
    display: flex;
    justify-content: space-between;
}

.ab-details-img {
    display: flex;
    justify-content: center;
    margin-left: 50px;
}

.ab-details p {
    margin-top: 0;
    margin-bottom: 30px;
}

.ab-details-p {
    width: 59%;
    margin-left: 100px;
}

.ab-details-p1 {
    font-size: 1.7em;
    line-height: 1.5em;
    font-weight: 500;
}

.ab-details-pr {
    font-size: 1.15em;
    line-height: 1.8em;
    font-weight: 400;
}

@media only screen and (max-width: 800px) {
    .ab-title {
        display: flex;
        justify-content: center;
    }

    .ab-title-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .ab-info-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .ab-info-wrapper {
        flex-direction: column;
        padding-bottom: 30px;
    }

    .ab-details {
        display: flex;
        width: 97%;
        flex-direction: column;
        align-items: center;
    }

    .ab-details-p {
        margin-top: 40px;
        width: 83%;
        margin-left: 0;
    }

    .ab-pic {
        width: 300px;
        height: 400px;
    }

    .ab-details-img {
        margin-left: 0;
    }

    .ab-details-img img {
        width: 270px;
        height: 334px;
        object-fit: contain;
    }

    .ab-span span {
        margin-top: 0;
        margin-bottom: 0;
    }

    .ab-span {
        font-size: 4rem;
        font-weight: 500;
        margin-left: 0px;
        text-align: center;
        margin-top: 0;
        margin-bottom: 0;
    }

    .ab-details-p1 {
        font-size: 1.5em;
    }
}