#contact {
    margin-top: 20px;
}

.ct-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.ct-info-wrapper {
    width: 600px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ct-form {
    max-width: 600px;
    width: 100%;
}

.ct-title {
    display: flex;
    font-size: 4rem;
    font-weight: 500;
    flex-direction: column;
    justify-content: center;
    width: 600px;
    margin-top: -150px;
}

.ct-title h3 {
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 4.7rem;
}

.ct-title span {
    color: gray;
    font-weight: 300;
    font-size: 1.33rem;
    width: 450px;
    margin-left: 3px;
}

.ct-title-span {
    color: gray;
}

.ct-field {
    padding: 1rem;
    width: 100%; 
}

.ct-form-ne {
    display: flex;
}

.form-btn {
    margin-top: 7px;
}

.ct-pref {
    margin-left: 15px;
    letter-spacing: 1px;
    margin-top: 40px;
    font-size: 0.95em;
    margin-bottom: 5px;
    color: gray;
}

.ct-em {
    margin-left: 15px;
    font: 0.9em;
}

@media only screen and (max-width: 800px) {
    .ct-info-wrapper {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .ct-title {
        width: 340px;
        text-align: center;
        margin-top: 15px;
        margin-bottom: 20px;
    }

    .ct-title h3 {
        font-size: 3.2rem;
        margin-top: -15px;
    }

    .ct-title span {
        font-size: 1.2rem;
        width: 340px;
    }

    .ct-form {
        width: 340px;
        margin-top: 0;
    }

    .field {
        width: 100%;
    }

    .form-btn {
        margin-left: 0;
        width: 100%;  
    }
}