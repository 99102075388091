.pf-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: 10px;
    margin-bottom: 40px;
}

.pf-proj1 {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}

.pf-proj {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}

.pf-proj-last {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}

.pf-proj1 h1 {
    font-size: 5em;
    padding: 0;
    margin: 0;   
}

.pf-proj h1 {
    font-size: 5em;
    padding: 0;
    margin: 0;
}

.pf-proj-last h1 {
    font-size: 5em;
    padding: 0;
    margin: 0;
}

.pf-proj-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 5;
    margin-bottom: 20px;
}

.pf-proj-name span {
    color: black;
    font-size: 0.9em;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 3.5px;
}

.pf-img {
    pointer-events: none;
    /* width: 38rem; */
    margin-right: 8px;
    transition: all 0.5s ease;
    box-shadow: rgba(50, 50, 93, 0.7) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.pf-proj-cover:hover .pf-img {
    transform: scale(1.06);
}

@media only screen and (max-width: 800px) {
    .pf-proj1 h1 {
        font-size: 4.2em;
    }

    .pf-proj h1 {
        font-size: 4.2em;
    }
    
    .pf-proj-last h1 {
        font-size: 4.5em;
    }

    .pf-img {
        width: 23rem;
        height: 200px;
        margin-left: 10px;
    }
}