.p1 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 40px;
}

.p1-img-wrapper {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 770px;
    margin: auto;
}

.p1-img-wrapper h1 {
    font-size: 5em;
    font-weight: 700;
    margin: 0;
}

.p1-img-wrapper h3 {
    font-size: 1em;
    font-weight: 400;
    color: gray;
    margin-top: 0;
    letter-spacing: 3.5px;
}

.project-img {
    object-fit: contain;
    box-shadow: rgba(50, 50, 93, 0.7) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.proj-img {
    width: 100%;
    object-fit: contain;
}

.p1-info-wrapper {
    display: flex;
    margin-top: 20px;
}

.p1-info p {
    line-height: 2.3rem;
    font-size: 1.5em;
    margin: 0;
    color: gray;
}

.p1-info span {
    font-size: 1.35em;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1.3px;
}

.p1-info h4 {
    font-size: 1.03em;
    margin-top: 10px;
    font-weight: 400;
    color: gray;
}

.p1-info-role {
    margin-right: 30px;
}

.p1-info-links {
    display: flex;
    flex-direction: column;
}

.p1-info-links h4 {
    margin: 0;
    margin-left: 10px;
}

.p1-links {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.p1-links:hover {
    text-decoration: underline;
    color: gray;
}

.contr h4 {
    margin-top: 10px;
    margin-bottom: 10px;
    color: black;
}

.p1-contr {
    margin-bottom: 25px;
}

.p1-contr span{
    font-weight: 800;
}

.p1-contr p {
    margin-top: 20px;
    font-weight: 400;
    font-size: 1.1em;
    line-height: 1.7rem;
    color: gray;
}

.p1-contr2 {
    margin-top: 25px;
}

.p1-contr2 span{
    font-weight: 700;
}

.p1-contr2 p {
    margin-top: 20px;
    font-weight: 400;
    font-size: 1.1em;
    line-height: 1.7rem;
    color: gray;
}

.player-wrapper {
    position: relative;
    padding-top: 56.25%;
}
  
.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.player-wrapper-p3 {
    position: relative;
    padding-top: 51.25%;
}

.react-player-p3 {
    position: absolute;
    top: 0;
    left: 0;
}

.proj-icon {
    margin-top: 40px;
    transition: all 0.5s ease;
}

.proj-icon:hover {
    transform: scale(1.2);
}

.p1-carousel {
    margin-bottom: 25px;
}

.carousel {
    box-shadow: rgba(50, 50, 93, 0.7) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
    height: 380px;
}

@media only screen and (max-width: 800px) {
    .p1-img-wrapper h1 {
        font-size: 3.9em;
    }

    .p1-img-wrapper h3 {
        font-size: 1em;
    }

    .proj-img {
        width: 340px;
        height: 100%;
    }

    .project-img {
        width: 340px;
        height: 100%;
    }

    .p1-img-wrapper {
        width: 420px;
    }

    .p1-info-wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    .p1-info {
        width: 340px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .p1-info span {
        font-size: 1.5em;
    }

    .p1-info-role {
        margin-right: 20px;
    }

    .p1-links {
        margin-right: 20px;
        margin-bottom: 20px;
    }

    .p1-info-links {
        width: 340px;
        flex-direction: row;
    }

    .p1-hr {
        width: 370px;
    }

    .player-wrapper {
        padding-top: 0;
    }

    .react-player {
        position: relative;
    }
    
    .player-wrapper-p3 {
        padding-top: 0;
    }

    .react-player-p3 {
        position: relative;
    }

    .carousel {
        box-shadow: rgba(50, 50, 93, 0.7) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
        height: 171px;
        width: 340px !important;
    }
}