.hp {
    display: flex;
    height: 90vh;
    justify-content: center;
    align-items: center;
}

.hp-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -80px;
}

.hp-name {
    display: flex;
    font-size: 5.8em;
    font-weight: 800;
}

.hp-title {
    margin-top: 15px;
    display: flex;
    font-size: 1.3em;
    font-weight: 600;
}

.hp-name-span {
    letter-spacing: 1rem;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
}

.hp-title-span {
    letter-spacing: 0.5rem;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
}

@media only screen and (max-width: 800px) {
    .hp-name {
        font-size: 2.7em;
    }

    .hp-title {
        font-size: 1em;
    }

    .hp-wrapper {
        margin-left: 18px;
    }
}