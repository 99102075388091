.menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 95;
    background-color: black;
    color: white;
    top: 0;
    left: 0;
}

.menu-wrap {
    margin-left: 20px;
}

.menu-div {
    font-size: 4.5em;
    margin-bottom: 30px;
    font-weight: 600;
    letter-spacing: 3px;
}

.menu-1 {
    font-size: 4.5em;
    margin-bottom: 30px;
    font-weight: 600;
    letter-spacing: 3px;
}

.menu-last {
    font-size: 4.5em;
    font-weight: 600;
    letter-spacing: 3px;
}

.menu-connect {
    display: none;
    margin-top: 12px;
    justify-content: flex-end;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    right: 0;
}

.menu-connect div {
    margin-right: 30px;
    margin-bottom: 15px;
}

.menu-wrap span {
    position: relative;
    display: inline-block;
}
  
.menu-wrap span:before {
    content: '';
    position: absolute;
    width: 0%;
    height: 3.5px;
    top: 50%;
    margin-top: -0.5px;
    background: white;
}

.menu-wrap span:after {
    content: '';
    position: absolute;
    width: 0%;
    height: 3.5px;
    top: 50%;
    margin-top: -0.5px;
    background: white;
}

.menu-wrap span:before {
    left: -2.5px;
}

.menu-wrap span:after {
    right: 2.5px;
    background: white;
    transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.menu-wrap span:hover:before {
    background: white;
    width: 100%;
    transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.menu-wrap span:hover:after {
    background: transparent;
    width: 100%;
    transition: 0s;
    height: 3.5px;
}

.menu-a {
    color: white;
    text-decoration: none;
}

@media only screen and (max-width: 800px) {
    .menu-connect {
        display: flex;
    }

    .menu-1 {
        font-size: 4em;
        margin-bottom: 30px;
        font-weight: 600;
        letter-spacing: 3px;
    }
    
    .menu-last {
        font-size: 4em;
        font-weight: 600;
        letter-spacing: 3px;
    }

    .menu-div {
        font-size: 4em;
        margin-bottom: 30px;
        font-weight: 600;
        letter-spacing: 3px;
    }
}